import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb5d553e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "hover-menu-icon"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "div-theme-name" }
const _hoisted_4 = {
  key: 0,
  class: "div-applied-tag"
}
const _hoisted_5 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogueCharts = _resolveComponent("DialogueCharts")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showCharts)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["dialoge-theme position-relative border-radius-10", { 'theme-selected': _ctx.localDialogueTheme.Id === _ctx.localSelectedThemeId, 'chart-outer-container-border': _ctx.localDialogueTheme.Id !== _ctx.localSelectedThemeId, }]),
          onMouseover: _cache[4] || (_cache[4] = ($event: any) => (_ctx.ShowHideHoverBar(true))),
          onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ShowHideHoverBar(false)))
        }, [
          _createElementVNode("div", null, [
            _createVNode(_component_DialogueCharts, { "chart-data": _ctx.localDialogueTheme }, null, 8, ["chart-data"])
          ]),
          (_ctx.isHoverBarVisible && _ctx.localDialogueTheme.IsCustomTheme)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                  class: "div-img-menu cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ShowMenu()))
                }, [
                  _createElementVNode("img", { src: _ctx.menuBarIcon }, null, 8, _hoisted_2)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isMenuVisible)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "hover-menu border-radius-10",
                onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ShowMenu()))
              }, [
                _createElementVNode("a", {
                  class: "menu-item cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ShowCustomThemePanel && _ctx.ShowCustomThemePanel(...args)))
                }, _toDisplayString(_ctx.$t("EventSetting.Dialogues.EditView")), 1),
                _createElementVNode("a", {
                  class: "menu-item cursor-pointer",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.ConfirmDelete && _ctx.ConfirmDelete(...args)))
                }, _toDisplayString(_ctx.$t("EventSetting.Dialogues.Delete")), 1)
              ], 32))
            : _createCommentVNode("", true)
        ], 34))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.localDialogueTheme.Id === _ctx.localAppliedThemeId)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("EventSetting.Dialogues.Applied")), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("label", {
        class: _normalizeClass(["font-size-12 font-weight-normal truncate-theme-name", { 'text-theme-selected': _ctx.localDialogueTheme.Id === _ctx.localSelectedThemeId, 'margin-left-55': _ctx.localDialogueTheme.Id === _ctx.localAppliedThemeId}]),
        title: _ctx.localDialogueTheme.Name
      }, _toDisplayString(_ctx.localDialogueTheme.Name), 11, _hoisted_5)
    ])
  ]))
}