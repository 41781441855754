
import {
  FeatureCards,
  IntegrationCards,
} from '@/entities/EventSetting/EventSettingCardViewModel';
import { Component, toNative, Vue } from 'vue-facing-decorator';
import Card from '@/components/Common/Card.vue';
import { LinkedFeatureTabList } from '@/entities/EventSetting/LinkedFeatureTabList';
import FeatureModuleEnum from '@/enums/FeatureModuleEnum';
import { CardViewModel } from '@/entities/CardViewModel';
import { ApplicationInstanceModel } from '@/entities/EventSetting/ApplicationInstanceModel';
import { LinkedFeatureViewModel } from '@/entities/LinkedFeatureViewModel';
import IntegrationModuleEnum from '@/enums/IntegrationModuleEnum';
import ControlType from '@/enums/ControlType';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import { StoreHelper } from '@/store/StoreHelper';
import CreateAccountType from '@/enums/CreateAccountType';
import { FetchData, Root, Store } from '@/main';
import Common from '@/helper/Common';
import ShowToaster from '@/entities/Emits/EventSettings/ShowToaster';
// Component
@Component({
  components: {
    Card,
  },
})
class FeaturesAndIntegrations extends Vue {
  private localEventDetail: ApplicationInstanceModel = ApplicationInstanceModel.createEmpty();
  private FeatureCards = new FeatureCards();
  private integrationCard: IntegrationCards = new IntegrationCards();
  private localFeatureCards = this.FeatureCards.FeatureCards;
  private localIntegrationCards = this.integrationCard.IntegrationCards;
  private linkedFeatures: LinkedFeatureTabList = new LinkedFeatureTabList();
  private showFeature = false;
  private isScreenDisable = false;
  private oldLocalapplicationInstanceModel: ApplicationInstanceModel = ApplicationInstanceModel.createEmpty();
  private isDirtyObject = true;
  private storeHelper: StoreHelper = new StoreHelper(Store);
  private reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
  private showCopiedMessage = false;
  private linkCopiedFadeoutTime = 1000;

  private mounted() {
    // catch updated data of ApplicationInstanceModel whenever data is update on event detail page and set this to localEventDetail
    Root.on('event-detail-dto', (val: any) => {
      this.localEventDetail = val;
      this.localEventDetail = Object.assign({}, val);
      this.SetFeatures(this.localEventDetail);
      this.setIntegrations(this.localEventDetail);
      this.showFeature = true;
      // assign updated data to oldLocalapplicationInstanceModel
      this.oldLocalapplicationInstanceModel = Object.assign({}, this.localEventDetail);
    });
    // sort feature cards according to there sort order
    this.localFeatureCards = this.localFeatureCards.sort(function(a, b) {
      return a.SortOrder - b.SortOrder;
    });
    // emit value for enable or disable background whenever unsaved changes popUp are their
    Root.on('disable-background-event-setting', (val: boolean) => {
      this.isScreenDisable = val;
    });
    // save feature tab data when user press save button from unsaved changes modal popup
    Root.on('save-features', () => {
      this.Submit();
    });

    Root.on('hide-loader', () => {
      Root.emit('show-loader', false);
    });
  }
  // set feature cards value based on there saved values in model
  private SetFeatures(eventDetail: ApplicationInstanceModel) {
    this.localFeatureCards.forEach((e) => {
      e.IsDisabled = false;
    });
    this.localFeatureCards[(FeatureModuleEnum.Program)-1].IsSelected=eventDetail.EnableProgram;
    if(!this.localFeatureCards[(FeatureModuleEnum.Program)-1].IsSelected) {
      // disabled link feature with program
      const values=this.linkedFeatures.LinkedFeatureTabList.find((a: LinkedFeatureViewModel) => a.MainId===FeatureModuleEnum.Program)!.LinkedId;
      values.forEach((element) => {
        this.localFeatureCards[element-1].IsSelected=false;
        this.localFeatureCards[element-1].IsDisabled=true;
      });
    }
    this.localFeatureCards[(FeatureModuleEnum.MyProgram)-1].IsSelected=eventDetail.EnableMyProgram;
    this.localFeatureCards[(FeatureModuleEnum.Information)-1].IsSelected=eventDetail.EnableInformation;
    // disable my information in case of application mode is not confirmed email
    if (this.reqInfo !== null && this.reqInfo !== undefined && this.reqInfo.CreateAccountTypeId !== CreateAccountType.Confirmedemail) {
      this.localFeatureCards[(FeatureModuleEnum.MyInformation)-1].IsSelected=false;
      this.localFeatureCards[(FeatureModuleEnum.MyInformation)-1].IsDisabled=true;
    } else {
      this.localFeatureCards[(FeatureModuleEnum.MyInformation)-1].IsSelected=eventDetail.EnableMyExtraInformation;
    }
    this.localFeatureCards[(FeatureModuleEnum.Dialogue)-1].IsSelected=eventDetail.EnableDialogue;
    this.localFeatureCards[(FeatureModuleEnum.Participants)-1].IsSelected=eventDetail.EnableParticipantList;
    if(!this.localFeatureCards[(FeatureModuleEnum.Participants)-1].IsSelected) {
      // disabled link feature with participant
      const values=this.linkedFeatures.LinkedFeatureTabList.find((a: LinkedFeatureViewModel) => a.MainId===FeatureModuleEnum.Participants)!.LinkedId;
      values.forEach((element) => {
        this.localFeatureCards[element-1].IsSelected=false;
        this.localFeatureCards[element-1].IsDisabled=true;
      });
    }
    // check flag is on from super admin
    if(this.reqInfo.IsLiveStreamingActive) {
      this.localFeatureCards[(FeatureModuleEnum.LiveStreaming)-1].IsSelected=eventDetail.EnableLiveStreaming;
      this.localFeatureCards[(FeatureModuleEnum.LiveStreaming)-1].ControlType=ControlType.CheckBox;
    } else {
      this.localFeatureCards[(FeatureModuleEnum.LiveStreaming)-1].IsSelected=eventDetail.EnableLiveStreaming;
      this.localFeatureCards[(FeatureModuleEnum.LiveStreaming)-1].ControlType=ControlType.a;
    }
    // check invite is availablein license item
    if(this.reqInfo.IsRegistrationPFActive) {
      this.localFeatureCards[(FeatureModuleEnum.VentlaInvite)-1].IsSelected=eventDetail.EnableRegistrationSystem;
      this.localFeatureCards[(FeatureModuleEnum.VentlaInvite)-1].ControlType=ControlType.CheckBox;
    } else {
      this.localFeatureCards[(FeatureModuleEnum.VentlaInvite)-1].IsSelected=eventDetail.EnableRegistrationSystem;
      this.localFeatureCards[(FeatureModuleEnum.VentlaInvite)-1].ControlType=ControlType.a;
    }
    // check event webpages is availablein license item
    if(this.reqInfo.IsEventWebPagePFActive) {
      this.localFeatureCards[(FeatureModuleEnum.EventWebPages)-1].ControlType=ControlType.CheckBox;
    } else {
      this.localFeatureCards[(FeatureModuleEnum.EventWebPages)-1].ControlType=ControlType.a;
    }
    this.localFeatureCards[(FeatureModuleEnum.VentlaInvite)-1].IsSelected=eventDetail.EnableRegistrationSystem;
    this.localFeatureCards[(FeatureModuleEnum.EventWebPages)-1].IsSelected=eventDetail.EnableEventWebPage;
    this.localFeatureCards[(FeatureModuleEnum.ActivityFeed)-1].IsSelected=eventDetail.EnableActivityFeeds;
    this.localFeatureCards[(FeatureModuleEnum.Chat)-1].IsSelected=eventDetail.EnableMessagingfeature;
    this.localFeatureCards[(FeatureModuleEnum.ImageGallery)-1].IsSelected = eventDetail.EnableImageArchive;
    this.localFeatureCards[(FeatureModuleEnum.Gamification)-1].IsSelected=eventDetail.EnableGamification;
    // for DEV-4435
    if (eventDetail.EnableNewsFeeds) {
      this.localFeatureCards[(FeatureModuleEnum.SocialMedia)-1].IsVisible = true;
    }
    this.localFeatureCards[(FeatureModuleEnum.Notes)-1].IsSelected=eventDetail.EnableNotes;
    if(!this.reqInfo.EnableUserEditedParticipant) {
      this.localFeatureCards[(FeatureModuleEnum.ActivityFeed)-1].IsDisabled=true;
      this.localFeatureCards[(FeatureModuleEnum.Gamification)-1].IsDisabled=true;
      this.localFeatureCards[(FeatureModuleEnum.Chat)-1].IsDisabled=true;
    }
  }
  // set IntegrationCards value based on there saved values in model
  private setIntegrations(eventDetail: ApplicationInstanceModel) {
    if(this.reqInfo.IsZapierPFActive) {
      this.localIntegrationCards[(IntegrationModuleEnum.Zapier)-1].IsSelected = eventDetail.EnableZapier;
      this.localIntegrationCards[(IntegrationModuleEnum.Zapier)-1].ControlType = ControlType.CheckBoxAndLabel;
      this.localIntegrationCards[(IntegrationModuleEnum.Zapier)-1].InputBoxData = this.reqInfo.ZapierKey;
    }
    if(eventDetail.EnableZapier) {
      this.SetLabelState(this.reqInfo.ZapierKey, this.reqInfo.IsZapierAvailableInApplicationZapierCallback);
    }
    if (eventDetail.IsEventBriteActive) {
      // change control type of event bright when feature is active
      this.localIntegrationCards[(IntegrationModuleEnum.EventBright)-1].ControlType = ControlType.label;
    }
    if (eventDetail.IsLyytiActive) {
      // change control type of lyyti when feature is active
      this.localIntegrationCards[(IntegrationModuleEnum.Lyyti)-1].ControlType = ControlType.label;
      this.localIntegrationCards[(IntegrationModuleEnum.Lyyti)-1].IsVisible = true;
    }
  }
  private SetLabelState(val: string, isCallbackReturned: boolean) {
    if(val === null || val === undefined || val === '') {
      this.localIntegrationCards[(IntegrationModuleEnum.Zapier)-1].Message = this.$t('EventSetting.ZapierIsNotConfigured').toString();
    } else {
      if(isCallbackReturned) {
        this.localIntegrationCards[(IntegrationModuleEnum.Zapier)-1].Message = this.$t('EventSetting.ZapierConfigured').toString();
      } else {
        this.localIntegrationCards[(IntegrationModuleEnum.Zapier)-1].Message = this.$t('EventSetting.ZapIsNotConfigured').toString();
      }
    }
  }
  // Check the given index number is even or not
  private IsEvenIndex(index: number): boolean {
    if (index % 2 === 0) {
      return true;
    } else {
      return false;
    }
  }
  private ChangeEventType(val: CardViewModel) {
    switch (val.Id) {
    case IntegrationModuleEnum.Zapier:
      this.localEventDetail.EnableZapier = val.IsSelected;
      this.SetLabelState(this.reqInfo.ZapierKey, this.reqInfo.IsZapierAvailableInApplicationZapierCallback);
    }
    // console.log('data');
  }
  // pass feature card in for loop
  private PasslocalFeatureCards() {
    return this.localFeatureCards;
  }
  // change feature card value on click of card
  private ChangeFeatureValue(val: CardViewModel) {
    let values: number[] = [];
    // match item based on their id and disable or enable value of linked features
    switch (val.Id) {
    case FeatureModuleEnum.Program:
      values = this.linkedFeatures.LinkedFeatureTabList.find((a: LinkedFeatureViewModel) => a.MainId === FeatureModuleEnum.Program)!.LinkedId;
      break;
    case FeatureModuleEnum.Participants:
      values = this.linkedFeatures.LinkedFeatureTabList.find((a: LinkedFeatureViewModel) => a.MainId === FeatureModuleEnum.Participants)!.LinkedId;
      break;
    }
    if (val.IsSelected === false) {
      values.forEach((element) => {
        this.localFeatureCards[element - 1].IsSelected = false;
        this.localFeatureCards[element - 1].IsDisabled = true;
      });
    } else {
      values.forEach((element) => {
        this.localFeatureCards[element - 1].IsSelected = true;
        this.localFeatureCards[element - 1].IsDisabled = false;
      });
    }
    // this.$mount();
    switch (val.Id) {
    case FeatureModuleEnum.Program:
      this.localEventDetail.EnableProgram = val.IsSelected;
      // program is linked with my program
      this.localEventDetail.EnableMyProgram = val.IsSelected;
      break;
    case FeatureModuleEnum.MyProgram:
      this.localEventDetail.EnableMyProgram = val.IsSelected;
      break;
    case FeatureModuleEnum.Information:
      this.localEventDetail.EnableInformation = val.IsSelected;
      break;
    case FeatureModuleEnum.MyInformation:
      this.localEventDetail.EnableMyExtraInformation = val.IsSelected;
      break;
    case FeatureModuleEnum.Dialogue:
      this.localEventDetail.EnableDialogue = val.IsSelected;
      break;
    case FeatureModuleEnum.Participants:
      // participant is linked with chat, gamification, activity feed
      this.localEventDetail.EnableParticipantList = val.IsSelected;
      this.localEventDetail.EnableMessagingfeature = val.IsSelected;
      this.localEventDetail.EnableGamification = val.IsSelected;
      this.localEventDetail.EnableActivityFeeds =val.IsSelected;
      break;
    case FeatureModuleEnum.LiveStreaming:
      this.localEventDetail.EnableLiveStreaming = val.IsSelected;
      break;
    case FeatureModuleEnum.VentlaInvite:
      this.localEventDetail.EnableRegistrationSystem = val.IsSelected;
      break;
    case FeatureModuleEnum.ActivityFeed:
      this.localEventDetail.EnableActivityFeeds = val.IsSelected;
      break;
    case FeatureModuleEnum.Chat:
      this.localEventDetail.EnableMessagingfeature = val.IsSelected;
      break;
    case FeatureModuleEnum.ImageGallery:
      this.localEventDetail.EnableImageArchive = val.IsSelected;
      break;
    case FeatureModuleEnum.Gamification:
      this.localEventDetail.EnableGamification = val.IsSelected;
      break;
    case FeatureModuleEnum.SocialMedia:
      this.localEventDetail.EnableNewsFeeds = val.IsSelected;
      break;
    case FeatureModuleEnum.Notes:
      this.localEventDetail.EnableNotes = val.IsSelected;
      break;
    case FeatureModuleEnum.EventWebPages:
      this.localEventDetail.EnableEventWebPage = val.IsSelected;
      break;
    }
  }
  // save or submit feature cards
  private Submit() {
    Root.emit('show-loader', true);
    // Save data
    this.localEventDetail.RegistrationTemplateName = this.localEventDetail.RegistrationTemplateId.toString();
    this.SaveEventSetting();
  }
  // Save or edit data api
  private SaveEventSetting() {
    const objForJson = {
      Latitude: this.localEventDetail.Latitude,
      Longitude: this.localEventDetail.Longitude,
    };
    FetchData('/ApplicationInstance/SaveOrEditApplicationInstance',
      JSON.stringify({
        applicationInstanceModel: this.localEventDetail,
        jsonData : JSON.stringify(objForJson),
      }))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response: any) => {
        if (response.ApplicationInstance !== null && response.ApplicationInstance !== undefined) {
          // disable dirty flag
          Root.emit('is-dirty',false);
          Root.emit('event-detail-dto-feature');
          // assign data to oldLocalapplicationInstanceModel
          this.oldLocalapplicationInstanceModel = Object.assign({}, this.localEventDetail);
          this.isDirtyObject = true;
          // save enable messaging feature in store
          this.storeHelper.SaveEnableMessagingfeature(response.ApplicationInstance.EnableMessagingfeature);
          // add or remove chat and discussion tab
          Root.emit('add-remove-chatanddiscussion-tab',response.ApplicationInstance.EnableMessagingfeature);
        }
        // reload application view modal for update left side menu bar
        Root.emit('reload-GetApplicationViewModel');
        // show hide save message and loader
        const toasterData = ShowToaster.createEmpty();
        setTimeout(() => {
          toasterData.Val = true;
          Root.emit('show-loader', false);
          Root.emit('show-toaster', toasterData);
          setTimeout(() => {
            toasterData.Val = false;
            Root.emit('show-toaster', toasterData);
          }, 2000);
        }, 1000);
      })
      .catch(() => {
        Root.emit('show-loader', false);
      });
  }
  // discard button click
  private Discard() {
    // assign old data in local object
    this.localEventDetail = Object.assign({}, this.oldLocalapplicationInstanceModel);
    // update feature cards
    this.SetFeatures(this.localEventDetail);
    this.setIntegrations(this.localEventDetail);
    // disable dirty flag
    Root.emit('is-dirty',false);
    this.isDirtyObject = true;
  }
  // check dirty state of local object
  private IsObjectDirty() {
    const objectState = JSON.stringify(this.localEventDetail) === JSON.stringify(this.oldLocalapplicationInstanceModel);
    if (!objectState) {
      // enbale butons after changes in object
      this.isDirtyObject = false;
      this.$emit('is-dirty');
      Root.emit('is-dirty',true);
    }
  }
  private ShowCopiedKey(val: string){
    this.showCopiedMessage = Common.copyPublicLinkToClipboard(
      val,
    );
    if (this.showCopiedMessage) {
      setTimeout(() => {
        this.showCopiedMessage = false;
      }, this.linkCopiedFadeoutTime);
    }
  }
}
export default toNative(FeaturesAndIntegrations);
