
import { Component, toNative, Vue } from 'vue-facing-decorator';
import { DialogueThemeEntity } from '@/entities/DialogueTheme/DialogueThemeEntity';
import { ApplicationInstanceModel } from '@/entities/EventSetting/ApplicationInstanceModel';
import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';

import Toaster from '@/components/Common/Toaster.vue';
import loading from '@/components/Common/loading.vue';
import DialogueSettings from '@/helper/DialogueThemesSettings';
import CustomTheme from './CustomTheme/CreateCustomTheme.vue';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import DialogueTheme from '@/components/EventSetting/DialoguesTab/DialogueTheme.vue';
import DialogueThemeDTO from '@/entities/Emits/EventSettings/DialogueThemes/DialogueThemeDTO';
import { FetchData, Root } from '@/main';
import ShowToaster from '@/entities/Emits/EventSettings/ShowToaster';
import ShowCustomThemePanel from '@/entities/Emits/EventSettings/DialogueThemes/ShowCustomThemePanel';
import DeleteDialogueTheme from '@/entities/Emits/EventSettings/DialogueThemes/DeleteDialogueTheme';

@Component({
  components: {
    DialogueTheme,
    Toaster,
    loading,
    CustomTheme,
    VueModalPopUp,
  },
})
class DialoguesTab extends Vue {
  // local variables
  private localEventDetail: ApplicationInstanceModel = ApplicationInstanceModel.createEmpty();
  private tooltipInfoVisible = false;
  private markAsDefaultTheme = false;
  private addIcon = require('@/assets/Images/add-icon-grey.svg');
  private greenTickIcon = require('@/assets/Images/green-tick.svg');
  private localDialogueThemes: DialogueThemeEntity[] = [];
  private appliedThemeId = 0;
  private oldAppliedThemeId = 0;
  private oldLocalDialogueThemes: DialogueThemeEntity[] = [];
  private oldLocalEventDetail: ApplicationInstanceModel = ApplicationInstanceModel.createEmpty();
  private isDirtyObject = true;
  private showCharts = false;
  private oldMarkAsDefaultTheme = false;
  private addThemeTooltipVisible = false;
  private isCustomThemePanelVisible = false;
  private customThemeData: DialogueThemeEntity = DialogueThemeEntity.createEmpty();
  private toasterDefaultTimeout = 3000;
  // Vue modal popup
  private showVueModalPopUp = false;
  private unsavedModalPopUp: VueModalPopUpViewModel = VueModalPopUpViewModel.createEmpty();
  private themeIdToBeDeleted = 0;
  private totalThemes  = 0;
  private deleteConfirmationMessage = '';
  private applyClientSideDialogueThemes = false;
  private oldApplyClientSideDialogueThemes = false;
  private defaultThemeId = 0;
  private tooltipInfoVisibleOfForceApplyTheme = false;
  private isDefaultTheme = false;
  private learnMoreLink = 'https://support.ventla.io/in-admin-tutorials/g5lc4iik0pUZjRg2sQFv/dialogue-themes';

  private mounted() {
    Root.on('dialogue-themes-dto',(dialogueData: DialogueThemeDTO ) => {
      // catch All themes object, applied theme id, event details object and assign in local object from Event details  component
      this.appliedThemeId = dialogueData.appThemeId;
      this.localDialogueThemes = [];
      this.localDialogueThemes =  Object.assign({}, dialogueData.dialogueTheme);
      const dialThemeObj = dialogueData.dialogueTheme.find((e) => e.Id === this.appliedThemeId);
      this.markAsDefaultTheme = dialThemeObj!.IsDefault === true ? true : false;
      this.isDefaultTheme = dialThemeObj!.IsDefault === true ? true : false;
      if (this.markAsDefaultTheme === true) {
        this.defaultThemeId = dialThemeObj!.Id;
      }
      this.localEventDetail.MarkAsDefaultTheme = this.markAsDefaultTheme;
      this.localEventDetail = Object.assign({}, dialogueData.eventDetail);
      this.applyClientSideDialogueThemes = this.localEventDetail.ApplyClientSideDialogueThemes;
      this.AssignObjectsInOldObject(true);
      this.totalThemes = dialogueData.dialogueTheme.length;
    },
    );
    Root.on('change-selected-theme', (selThemeId: number) => {
      // catch chnaged selected theme id and assign in object from DialogueCharts component
      if (!(this.localEventDetail.DialogueThemeId === selThemeId)) {
        this.markAsDefaultTheme = this.localEventDetail.MarkAsDefaultTheme = false;
        this.isDefaultTheme = false;
      }
      if (this.defaultThemeId === selThemeId) {
        this.markAsDefaultTheme = this.localEventDetail.MarkAsDefaultTheme = true;
        this.isDefaultTheme = true;
      }
      this.localEventDetail.DialogueThemeId = selThemeId;
    });
    Root.on('show-custom-theme-panel',(data: ShowCustomThemePanel)=> {
      this.isCustomThemePanelVisible = data.Val;
      this.customThemeData = Object.assign({},data.DialogueThemeData);
    });
    Root.on('save-dialogue-themes', () => {
      this.Submit();
    });
    Root.on('confirm-delete',(deletionData: DeleteDialogueTheme)=> {
      if(deletionData.DeleteAppliedTheme) {
        this.deleteConfirmationMessage = this.$t('EventSetting.Dialogues.DeleteAppliedThemePopUpDesciption').toString();
      } else {
        this.deleteConfirmationMessage = this.$t('EventSetting.CustomTheme.DeleteThemePopUpDesciption').toString();
      }
      this.SetModelPopUpMessage();
      this.showVueModalPopUp = deletionData.Val;
      this.themeIdToBeDeleted = deletionData.ThemeId;
    });
    this.showCharts = true;
    this.FillDefaultCustomThemeValues();
  }

  private ShowHideInformationString(val: boolean) {
    this.tooltipInfoVisible = val;
  }

  private ShowHideInformationStringOfForceApplyTheme(val: boolean) {
    this.tooltipInfoVisibleOfForceApplyTheme = val;
  }
  private ShowHideAddButtonInformation(val: boolean) {
    this.addThemeTooltipVisible = val;
  }
  private changeDefaultThemeCheckBox() {
    this.markAsDefaultTheme = !this.markAsDefaultTheme;
    this.localEventDetail.MarkAsDefaultTheme = this.markAsDefaultTheme;
  }
  private FillDefaultCustomThemeValues() {
    this.customThemeData.ThemeColors = DialogueSettings.ThemeColor;
    this.customThemeData.ChartColors = DialogueSettings.ChartColors;
    this.customThemeData.FontColor = DialogueSettings.FontColor;
    this.customThemeData.BackgroundColor = DialogueSettings.BackgroundColor;
    this.customThemeData.Chartdata = DialogueSettings.PreviewChartsData;
    this.customThemeData.ChartTitle = this.$t('Title').toString();
  }
  private ShowCustomThemePanel(val: boolean) {
    val = this.totalThemes >= 11 ? false : val;
    this.isCustomThemePanelVisible = val;
  }
  private HideCustomThemePanel(val: boolean) {
    this.customThemeData = DialogueThemeEntity.createEmpty();
    this.FillDefaultCustomThemeValues();
    this.isCustomThemePanelVisible = val;
  }
  private AssignObjectsInOldObject(assignInOldObject: any) {
    // assign object
    if (assignInOldObject) {
      this.oldAppliedThemeId = this.appliedThemeId;
      this.oldLocalDialogueThemes = Object.assign({}, this.localDialogueThemes);
      this.oldLocalEventDetail = Object.assign({}, this.localEventDetail);
      this.oldMarkAsDefaultTheme = this.markAsDefaultTheme;
      this.oldApplyClientSideDialogueThemes = this.applyClientSideDialogueThemes;
    } else {
      this.appliedThemeId = this.oldAppliedThemeId;
      this.localDialogueThemes = Object.assign({}, this.oldLocalDialogueThemes);
      this.localEventDetail = Object.assign({}, this.oldLocalEventDetail);
      this.markAsDefaultTheme = this.oldMarkAsDefaultTheme;
      this.applyClientSideDialogueThemes = this.oldApplyClientSideDialogueThemes;
    }
  }

  // check object is dirty or not
  private IsObjectDirty() {
    const objectState = this.compareObject();
    if (!objectState) {
      this.IsDirtyFlag();
    }
  }
  private CloseModelPopup() {
    this.showVueModalPopUp = false;
  }

  // compare oject for dirty check and unsaved changes
  private compareObject() {
    return (
      JSON.stringify(this.localDialogueThemes)===
      JSON.stringify(this.oldLocalDialogueThemes)&&
      JSON.stringify(this.localEventDetail)===
      JSON.stringify(this.oldLocalEventDetail)&&
      JSON.stringify(this.appliedThemeId)===
      JSON.stringify(this.oldAppliedThemeId)&&
      JSON.stringify(this.oldMarkAsDefaultTheme)===
      JSON.stringify(this.markAsDefaultTheme) &&
      JSON.stringify(this.oldApplyClientSideDialogueThemes)===
      JSON.stringify(this.applyClientSideDialogueThemes)
    );
  }

  // Set dirty flag and emit
  private IsDirtyFlag() {
    this.isDirtyObject = false;
    this.$emit('is-dirty');
    Root.emit('is-dirty', true);
  }

  // Discard all the changes
  private Discard() {
    this.showCharts = false;
    // assign all old values in local varialbles
    this.AssignObjectsInOldObject(false);
    this.isDirtyObject = true;
    this.showCharts = true;
    Root.emit('is-dirty',false);
  }

  // sumit data
  private Submit() {
    Root.emit('show-loader', true);
    // Save data
    this.localEventDetail.RegistrationTemplateName = this.localEventDetail.RegistrationTemplateId.toString();
    this.SaveEventSetting();
  }

  // Save api
  private SaveEventSetting() {
    this.localEventDetail.MarkAsDefaultTheme =this.markAsDefaultTheme;
    const objForJson = {
      Latitude: this.localEventDetail.Latitude,
      Longitude: this.localEventDetail.Longitude,
    };
    FetchData('/ApplicationInstance/SaveOrEditApplicationInstance',
      JSON.stringify({
        applicationInstanceModel: this.localEventDetail,
        jsonData : JSON.stringify(objForJson),
      }))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response: any) => {
        if (response.ApplicationInstance !== null && response.ApplicationInstance !== undefined) {
          // disable dirty flag
          Root.emit('is-dirty',false);
          Root.emit('event-detail-dto-feature');
          // assign data to oldLocalapplicationInstanceModel
          this.oldLocalEventDetail = Object.assign({}, this.localEventDetail);
          this.isDirtyObject = true;
        }
        // reload application view modal for update left side menu bar
        Root.emit('reload-GetApplicationViewModel');
        // show hide save message and loader
        const toasterData = ShowToaster.createEmpty();
        setTimeout(() => {
          toasterData.Val = true;
          toasterData.Message = this.$t('EventSetting.SuccessUpdateMessage').toString();
          Root.emit('show-loader', false);
          Root.emit('show-toaster', toasterData);
          setTimeout(() => {
            toasterData.Val = false;
            Root.emit('show-toaster', toasterData);
          }, 2000);
        }, 1000);
      })
      .catch(() => {
        Root.emit('show-loader', false);
      });
  }
  private SetModelPopUpMessage() {
    this.unsavedModalPopUp = new VueModalPopUpViewModel(
      this.$t('EventSetting.Dialogues.DeleteThemePopUpHeading').toString(),
      this.deleteConfirmationMessage ,
      true,
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require('@/assets/Images/Icon-warning.svg'),
      this.$t('CancelButton').toString(),
      this.$t('DeleteButton').toString(),
    );
  }
  private DeleteModelPopUp() {
    this.showVueModalPopUp = false;
    Root.emit('show-loader', true);
    FetchData('/ApplicationInstance/DeleteCustomTheme',
      JSON.stringify({
        themeId: this.themeIdToBeDeleted,
      }))
      .then(() => {
        Root.emit('event-detail-dto-feature');
        Root.emit('show-loader', false);
        const toasterData = ShowToaster.createEmpty();
        toasterData.Message = this.$t('EventSetting.CustomTheme.successfullyDeletedMessage').toString();
        toasterData.Val = true;
        Root.emit('show-toaster', toasterData);
        setTimeout(() => {
          toasterData.Val = false;
          Root.emit('show-toaster', toasterData);
        }, this.toasterDefaultTimeout);
      })
      .catch(() => {
        Root.emit('show-loader', false);
      });
  }
  // Assign DefaultCustomizedThemeCounts value in text
  private GetText() {
    return this.$t('EventSetting.Dialogues.MaximumCustomisedThemes', {type: DialogueSettings.DefaultCustomizedThemeCounts});
  }
  private changeApplyThemeCheckBox() {
    this.applyClientSideDialogueThemes = !this.applyClientSideDialogueThemes;
    this.localEventDetail.ApplyClientSideDialogueThemes = this.applyClientSideDialogueThemes;

  }
}
export default toNative(DialoguesTab);
